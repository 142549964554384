import React, { useContext } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';

import { SocketContext } from '../Context';

const useStyles = makeStyles((theme) => ({
  video: {
    border: '5px solid #fff',
    width: 'calc(100% - 10px)',
    height: '75vh',
    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 10px)',
      maxHeight: '240px',
      objectFit: 'cover',
    },
  },
  gridContainer: {
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  vBottom: {
    fontSize: '1.25rem',
    lineHeight: '2.334',
    textAlign: 'center',
    background: '#fff',
    border: '5px solid #f1f1f1',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.05rem',
      lineHeight: '1.6',
      marginTop: '-40px',
    },
  },
}));

const VideoPlayer = () => {
  const { name, callAccepted, myVideo, userVideo, callEnded, stream, call } = useContext(SocketContext);
  const classes = useStyles();

  return (
    <Grid container className={classes.gridContainer}>
      {stream && (
        <Grid item xs={12} md={6}>
          <video playsInline muted ref={myVideo} autoPlay className={classes.video} />
          <Typography className={classes.vBottom} variant="h5" gutterBottom>{name || 'Name'}</Typography>
        </Grid>
      )}
      {callAccepted && !callEnded && (
        <Grid item xs={12} md={6}>
          <video playsInline ref={userVideo} autoPlay className={classes.video} />
          <Typography className={classes.vBottom} variant="h5" gutterBottom>{call.name || 'Name'}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default VideoPlayer;
