import React from 'react';
import { Grid, AppBar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import VideoPlayer from './components/VideoPlayer';
import Sidebar from './components/Sidebar';
import Notifications from './components/Notifications';

const useStyles = makeStyles((theme) => ({
  appBar: {
    borderRadius: 0,
    margin: '0px 0px',
    padding: '0px 25px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    width: '100%',
    border: '0px solid black',
    background: '#fff',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  image: {
    marginLeft: '15px',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
}));

const App = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <AppBar className={classes.appBar} position="static" color="inherit">
        <img className="p-1 loho-mob" src="/logo.png" />
      </AppBar>
      <Grid container>
        <Grid item xs={12} md={9}>
          <VideoPlayer />
        </Grid>
        <Sidebar>
          <Notifications />
        </Sidebar>
      </Grid>
    </div>
  );
};

export default App;
